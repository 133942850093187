import React, { useEffect, useState } from 'react'
import './App.css'
import './cover.css'
import TextTransition, { presets } from 'react-text-transition'
import { AwesomeButton } from 'react-awesome-button'
import { ZapIcon } from '@primer/octicons-react'
import IdeaModal from './IdeaModal'

const TEXTS = ['mini-app', 'Frame', 'Blink']

function App() {
  const [isShowModal, setIsShowModal] = useState(false)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        1500,
    )
    return () => clearTimeout(intervalId)
  }, [])

  return (
      <>
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <header className="mb-5">
            <div>
              <h3 className="float-md-start mb-0">Web4.Build</h3>
              <nav className="nav nav-masthead justify-content-center float-md-end">
                {/*<a className="nav-link fw-bold py-1 px-0 active" aria-current="page" href="/">Home</a>*/}
              </nav>
            </div>
          </header>

          <div className="min-vh-100 d-flex justify-content-center align-items-center">
            <main style={{marginTop: -200, width: '100%'}}>

              <h1>Create a <TextTransition springConfig={presets.wobbly}
                                           inline={true}>{TEXTS[index % TEXTS.length]}</TextTransition> using AI.</h1>

              <p className="lead">
                <AwesomeButton className="mt-5" onPress={(e: any) => {
                  e.preventDefault()
                  setIsShowModal(true)
                }} type="primary"
                               before={<ZapIcon/>}>Create</AwesomeButton>
              </p>

              <div className="mt-5 video-responsive">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vqqtshZVEWw"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
            </main>
          </div>

          <footer className="mt-auto text-white-50">
            <p>
              <a
                  href="mailto:igor.shadurin@gmail.com" className="text-white">igor.shadurin@gmail.com</a>
            </p>
          </footer>
          <IdeaModal isShow={isShowModal} onClose={() => setIsShowModal(false)}/>
        </div>
      </>
  )
}

export default App
