import React, { useRef, useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { ZapIcon } from '@primer/octicons-react'
import { AwesomeButton } from 'react-awesome-button'
import { useForm } from '@formspree/react';

export function IdeaModal({isShow, onClose}: { isShow: boolean; onClose: () => void }) {
  const [idea, setIdea] = useState('')
  const [state, handleSubmit] = useForm("xdkngwvg");
  const formRef = useRef(null)

  if (state.succeeded) {
    onClose()
  }

  const handleProceed = (e: any) => {
    // e.preventDefault()
    // console.log('e', e)
    // if (onClose) {
    //   onClose()
    // }
    //
    // handleSubmit(e.target).then()
  }

  return (
      <>
        <Modal show={isShow} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Your Idea</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Form.Group controlId="formIdea">
                <Form.Label>Idea</Form.Label>
                <Form.Control
                    name="idea"
                    as="textarea"
                    rows={3}
                    value={idea}
                    onChange={(e) => setIdea(e.target.value)}
                    placeholder="Enter your idea here..."
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <AwesomeButton
                // @ts-ignore
                onPress={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
                           type="primary" before={<ZapIcon/>} disabled={idea.length < 3 || state.submitting}>Send</AwesomeButton>
          </Modal.Footer>
        </Modal>
      </>
  )
}

export default IdeaModal
